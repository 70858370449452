import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

const AirconditioningPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/airconditioning/hero.jpg" }) {
        ...heroImage
      }
      airconditioning: file(
        relativePath: {
          eq: "images/airconditioning/services/airconditioning.jpg"
        }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Airconditioning - Uytterhaegen Jan BV"
        pathname="/airconditioning"
      />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Airconditioning</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Airconditioning</h1>
                  <p>
                    Om uw wooncomfort tijdens de warme zomermaanden te behouden,
                    kunnen wij voor een perfecte airconditioning zorgen.{" "}
                  </p>
                  <p>
                    Een goede luchtkwaliteit, een bescheiden uiterlijk, minimaal
                    geluid en gebruiksvriendelijke bediening, daar zorgen we
                    voor.
                  </p>
                  <p>
                    <strong>
                      De nieuwe generatie airco’s zijn daarenboven intelligenter
                      , milieuvriendelijker en zuiniger.
                    </strong>
                  </p>
                  <p>
                    <ul>
                      <li>
                        Uiterst stil (16dB(A)) dankzij geoptimaliseerd ontwerp
                        van luchtkanalen
                      </li>
                      <li>
                        Intelligente luchtstroom en eco-sensor die automatisch
                        scant op aanwezigheid van mensen en de luchtstroom
                        aanpast.
                      </li>
                      <li>
                        Wifi zodat je je airconditioner, waar je ook bent en op
                        elk moment, kan bedienen
                      </li>
                      <li>
                        Comfortabele slaapmodus, waarbij het toestel de
                        temperatuur voor het slapengaan en bij opstaan
                        automatisch aanpast.
                      </li>
                      <li>
                        Cleancool, deze technologie stelt je in staat om
                        bacteriën te doden en de verdamper schoon te houden
                      </li>
                      <li>
                        De Puricool-airconditioner maakt gebruik van innovatief
                        IFD-filter om alle verontreinigende stoffen en
                        allergenen in de lucht met een efficiëntie tot 99.9% te
                        elimineren.
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.airconditioning.childImageSharp.name}
                    fluid={data.airconditioning.childImageSharp.fluid}
                  />
                </div>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AirconditioningPage
